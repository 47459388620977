import { AccountTransaction } from '../types/banks'

const currencyLocales: Record<string, string> = {
    DKK: 'da-DK',
}

export function formatCurrencyValue(v: number, currency = 'DKK') {
    const locale = currencyLocales[currency.toUpperCase()]
    if (!locale) {
        return Math.round(v).toString()
    }
    return v.toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
    })
}

export function getTransactionDate(transaction: AccountTransaction): Date {
    return transaction.value_date || transaction.booking_date
}
