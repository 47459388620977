import * as React from 'react'
import createAlertSnackbar from './snackbars'
import createActionDialog from './actionDialog'
import { Box, Typography } from '@mui/material'
import { ActionButton } from '../input/styleOverrides'
import { buildDocumentForTransaction, getAccountTransaction } from '../../api/banks'
import { getUserTypeFunctions } from '../../lib/apiFunctions'
import { ComplianceReportsTypes } from '../../lib/enums'
import { useAuth } from '../../hooks/useAuth'
import { PDFViewer } from '../Camera/PDFRender'
import { navigate } from 'gatsby'
import { DatePicker } from 'antd'
import * as dayjs from 'dayjs'
import moment from 'moment'

export default function CreateDocumentFromTransactionDialog(
    onDocumentChanged = (transactionId: string) => {}
) {
    const [transaction, setTransaction] = React.useState<any>({})
    const [accountId, setAccountId] = React.useState<string | null>(null)
    const [loading, setLoading] = React.useState(true)
    const [document, setDocument] = React.useState<any>(null)
    const apiFunctions = getUserTypeFunctions(ComplianceReportsTypes.LLUser)
    const { session } = useAuth('')
    const [documentDate, setDocumentDate] = React.useState(transaction?.booking_date)

    async function getDocumentation(transaction) {
        if (!transaction.ll_documentation_id || !session?.user?.user_id) {
            setDocument(null)
            return null
        }
        const data = await apiFunctions.getDocument(
            session?.user?.user_id,
            transaction.ll_documentation_id
        )
        if (data?.document) {
            const document = data?.document
            setDocument(document)
            return document
        } else {
            setDocument(null)
            return null
        }
    }

    async function onOpen(newAccountId: string, newTransactionId: string) {
        if (!newTransactionId || !newAccountId) return
        setAccountId(newAccountId)
        const newTransaction = await getAccountTransaction(newAccountId, newTransactionId)
        setTransaction(newTransaction)
        const document = await getDocumentation(newTransaction)
        setDocumentDate(
            !!document?.document_date ? document.document_date : newTransaction?.booking_date
        )
        setLoading(false)
        openDialog()
    }

    async function buildDocument() {
        setLoading(true)
        if (accountId && transaction) {
            await buildDocumentForTransaction(
                accountId,
                transaction.id,
                dayjs(documentDate).format('YYYY-MM-DD')
            )
            const newTransaction = await getAccountTransaction(accountId, transaction.id)
            setTransaction(newTransaction)
            await getDocumentation(newTransaction)
            await onDocumentChanged(transaction.id)
        }
        setLoading(false)
    }

    function navigateToCalendar() {
        navigate(`/app?calendar_day=${dayjs(documentDate).format('YYYY-MM-DD')}`)
    }

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="h6" sx={{ my: 2 }}>
                Bilag for transaktion
            </Typography>
            {!document?.url && (
                <>
                    <Typography variant="body2" gutterBottom>
                        Lad os automatisk generere et bilag på denne bank transaktion og vedlæg den
                        på den pågældende dag.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Transaktionstekst:
                        <br />
                        <Typography component="span" color="black">
                            {transaction.transaction_text}
                        </Typography>
                    </Typography>
                    <Typography variant="body2">Dato for bilag (tryk for at ændre):</Typography>
                    <DatePicker
                        popupStyle={{ zIndex: 987654321 }}
                        value={moment(documentDate)}
                        onChange={(newValue) => {
                            setDocumentDate(newValue?.toDate())
                        }}
                    />
                    <Typography variant="body2" color="black">
                        beløb: {transaction?.amount}
                    </Typography>
                </>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 2,
                    mx: 1,
                }}
            >
                {document?.url && (
                    <Box sx={{ border: '1px solid black', p: 1, mx: 1 }}>
                        <PDFViewer filePath={document?.url} widthDescreasePx={50} />
                    </Box>
                )}
                {!!document?.url ? (
                    <>
                        <Typography>
                            Bilagsdato: {dayjs(documentDate).format('YYYY-MM-DD')}
                        </Typography>
                        <ActionButton
                            loading={loading}
                            className="autotest-save-button"
                            onClick={navigateToCalendar}
                            variant="outlined"
                        >
                            Gå til kalender
                        </ActionButton>
                    </>
                ) : (
                    <ActionButton
                        loading={loading}
                        className="autotest-save-button"
                        onClick={buildDocument}
                        variant="contained"
                    >
                        Opret bilag
                    </ActionButton>
                )}
            </Box>
        </Box>
    )

    const { openAlert, AlertComponent } = createAlertSnackbar('success')
    const { openDialog, closeDialog, DialogComponent } = createActionDialog(
        () => {},
        () => {},
        'Dusør',
        content,
        undefined,
        undefined,
        'info',
        'info',
        true
    )

    return {
        Component: (
            <>
                {DialogComponent}
                {AlertComponent}
            </>
        ),
        openDialog: onOpen,
    }
}
