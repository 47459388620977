import Typography from '@mui/material/Typography'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import AccountTransactionListItem from './TransactionListItem'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { BanksPaper } from './paperComponents'
import Stack from '@mui/material/Stack'
import { useLocation } from '@reach/router'
import LinearProgress from '@mui/material/LinearProgress'
import { useBankAccount, useBankAccountTransactions } from '../../hooks/useBankData'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { updateAccountInclusion } from '../../api/banks'
import { Box } from '@mui/material'
import CreateDocumentFromTransactionDialog from '../dialogs/CreateDocumentFromTransactionDialog'

const StyledListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

function AccountTransactions() {
    const [accountId, setAccountId] = React.useState<string | null>(null)

    const [pickCategoryTransactionId, setPickCategoryTransactionId] = React.useState<string | null>(
        null
    )

    const openPickCategoryDialog = (transactionId: string) => {
        if (!accountId) return
        setPickCategoryTransactionId(transactionId)
        openDialog(accountId, transactionId)
    }

    const { data: account, loading: fetchAccountLoading } = useBankAccount(accountId)
    const {
        data: transactionsMap,
        loading: fetchTransactionsLoading,
        refresh: refreshTransactions,
    } = useBankAccountTransactions(accountId)
    const transactions = (accountId && transactionsMap[accountId]) || []
    let location = useLocation()

    const { Component, openDialog } = CreateDocumentFromTransactionDialog((tId) =>
        refreshTransactions()
    )

    React.useEffect(() => {
        const accountId = new URLSearchParams(location.search).get('account_id')
        if (!accountId) return
        setAccountId(accountId)
    }, [location.search])

    React.useEffect(() => {
        if (transactions.length > 0) {
            setPickCategoryTransactionId(transactions[0].id)
        }
    }, [transactions])

    return (
        <Box>
            {Component}
            <Stack sx={{ marginBottom: fetchAccountLoading || fetchTransactionsLoading ? 2 : 5 }}>
                <Stack direction="row" spacing={2}>
                    <Typography variant="overline" component="div" gutterBottom>
                        Transactions
                    </Typography>
                    <Typography variant="body1" component="div" gutterBottom>
                        {account?.name || account?.owner_name}
                    </Typography>
                </Stack>
            </Stack>
            {(fetchAccountLoading || fetchTransactionsLoading) && (
                <LinearProgress sx={{ marginBottom: 2 }} />
            )}
            <List disablePadding sx={{ maxHeight: '80vh', overflow: 'auto' }}>
                {transactions.map((transaction: any) => {
                    return (
                        <StyledListItem key={transaction.id} disablePadding>
                            <AccountTransactionListItem
                                selected={pickCategoryTransactionId === transaction.id}
                                accountName={account?.name || account?.owner_name || ''}
                                accountLogoUrl={account?.bank_institution?.logo_url}
                                openPickCategoryDialog={openPickCategoryDialog}
                                transaction={transaction}
                            />
                        </StyledListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default AccountTransactions
