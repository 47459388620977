import * as React from 'react'
import AppPageWraper from '../../../../components/PageWrappers/AppPageWraper'
import AccountTransactions from '../../../../components/Bank/AccountTransactions'

function AppStartPage() {
    return (
        <AppPageWraper>
            <AccountTransactions />
        </AppPageWraper>
    )
}

export default AppStartPage
