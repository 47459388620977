import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import ListItemButton from '@mui/material/ListItemButton'
import { AccountTransaction } from '../../types/banks'
import { getTransactionDate } from '../../utils/numbers'
import { CardMedia, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'

const useStyles = makeStyles({
    bankCard: {
        width: '100%',
    },
    balanceText: {
        // textAlign: 'left',
        justifyContent: 'center',
    },
    contentGrid: {
        width: '100%',
    },
})

export interface CategoriseBankTransactionDialogProps {
    selected: boolean
    transaction: AccountTransaction
    accountName: string
    openPickCategoryDialog: (txId: string) => void
    accountLogoUrl?: string
}

export function AccountTransactionListItemSkeleton() {
    const classes = useStyles()
    return (
        <Card className={classes.bankCard} sx={{ display: 'flex', width: '100%' }}>
            <ListItemButton>
                <Skeleton variant="circular" width={50} height={50} />
                <CardContent>
                    <Grid className={classes.contentGrid} container spacing={0}>
                        <Grid item xs={8}>
                            <Box>
                                <Skeleton width={140} variant="text" />
                                <Skeleton width={160} variant="text" />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton width={60} variant="text" />
                        </Grid>
                    </Grid>
                </CardContent>
            </ListItemButton>
        </Card>
    )
}

function AccountTransactionListItem({
    selected,
    transaction,
    accountName,
    accountLogoUrl,
    openPickCategoryDialog,
}: CategoriseBankTransactionDialogProps) {
    const classes = useStyles()
    return (
        <Card className={classes.bankCard} sx={{ display: 'flex' }}>
            <ListItemButton
                selected={selected}
                onClick={() => openPickCategoryDialog(transaction.id)}
            >
                <CardMedia
                    component="img"
                    sx={{ width: 50 }}
                    image={accountLogoUrl}
                    alt="Intitution logo"
                />
                <Box sx={{ width: '100%' }}>
                    <CardContent>
                        <Grid
                            className={classes.contentGrid}
                            sx={{ width: '500px' }}
                            container
                            spacing={0}
                        >
                            <Grid item xs={6}>
                                <Box>
                                    <Typography component="div" variant="body1">
                                        {transaction.transaction_text}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.secondary"
                                        component="div"
                                    >
                                        {getTransactionDate(transaction)}
                                        <br />
                                        {accountName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    className={classes.balanceText}
                                    variant="h6"
                                    component="div"
                                >
                                    {transaction.amount} {transaction.currency}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {transaction?.ll_documentation_id != null && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <AssignmentTurnedInIcon
                                            sx={{ color: '#7EAC55', margin: 'auto' }}
                                        />
                                        <Typography
                                            className={classes.balanceText}
                                            variant="caption"
                                            component="div"
                                        >
                                            Bilag oprettet
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </ListItemButton>
        </Card>
    )
}

export default AccountTransactionListItem
