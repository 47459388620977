import * as React from 'react'
import { useEffect } from 'react'
import { Account, AccountTransaction } from '../types/banks'
import { getAccount, getAccountTransactions, getAccounts, getAllTransactions } from '../api/banks'

export type UseData<T> = {
    data: T
    loading: boolean
    refresh: () => Promise<void>
    updateTransaction?: (input: T | any) => void
}

export const useBankAccounts = (): UseData<Account[]> => {
    const [data, setData] = React.useState<Account[]>([])
    const [loading, setLoading] = React.useState(true)

    async function refresh() {
        setLoading(true)
        const data = await getAccounts()
        setData(data)
        setLoading(false)
    }

    useEffect(() => {
        refresh()
    }, [])

    return {
        data,
        loading,
        refresh,
    }
}

export const useBankAccount = (accountId: string | null): UseData<Account | null> => {
    const [data, setData] = React.useState<Account | null>(null)
    const [loading, setLoading] = React.useState(true)

    async function refresh() {
        setLoading(true)
        if (!accountId) return
        const data = await getAccount(accountId)
        setData(data)
        setLoading(false)
    }

    useEffect(() => {
        refresh()
    }, [accountId])

    return {
        data,
        loading,
        refresh,
    }
}

export const useBankTransactions = (): UseData<AccountTransaction[]> => {
    const [data, setData] = React.useState<AccountTransaction[]>([])
    const [loading, setLoading] = React.useState(true)

    async function refresh() {
        setLoading(true)
        const data = await getAllTransactions()
        setData(data)
        setLoading(false)
    }

    async function updateTransaction(newTransaction: AccountTransaction) {
        setLoading(true)
        setData(
            data.map((tx) => {
                if (tx.id === newTransaction.id) {
                    return newTransaction
                }
                return { ...tx }
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        refresh()
    }, [])

    return {
        data,
        loading,
        refresh,
        updateTransaction,
    }
}

export type UseAccountTransactions = Record<string, AccountTransaction[]>

export const useBankAccountTransactions = (
    accountIdInput: (Account | string | null)[] | (Account | string | null)
): UseData<UseAccountTransactions> => {
    const [data, setData] = React.useState<UseAccountTransactions>({})
    const [loading, setLoading] = React.useState(true)

    async function addAccountTransactions(accountId: string | null) {
        if (!accountId) return {}
        const transactions = await getAccountTransactions(accountId)
        return {
            accountId,
            transactions,
        }
    }

    function getAccountIds() {
        let accountIds: (string | null)[] = []
        if (!accountIdInput) return
        else if (typeof accountIdInput === 'string') {
            accountIds = [accountIdInput as string]
        } else if (typeof (accountIdInput as Account)?.id === 'string') {
            accountIds = [(accountIdInput as Account).id]
        } else if ((accountIdInput as any[]).length < 1) {
            return
        } else {
            accountIds = (accountIdInput as any[]).map((acc) => acc?.id || acc)
        }
        return accountIds
    }

    async function refresh() {
        setLoading(true)
        let accountIds = getAccountIds()
        if (!accountIds) return
        const result = await Promise.all(
            accountIds.map((accountId) => addAccountTransactions(accountId))
        )
        const newData: UseAccountTransactions = {}
        for (const accountData of result) {
            if (!accountData.accountId) continue
            newData[accountData.accountId] = accountData.transactions
        }
        setData(newData)
        setLoading(false)
    }

    useEffect(() => {
        refresh()
    }, [accountIdInput])

    return {
        data,
        loading,
        refresh,
    }
}
